import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  latestBlogs: [],
};

const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    //Latest blogs
    setLatestBlogs: (state, action) => {
      state.latestBlogs = action.payload;
    },
  },
});

export const { setLatestBlogs } = blogSlice.actions;
export default blogSlice.reducer;
