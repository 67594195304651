import React from "react";
import "./WhyCSstandsOut.css";

const WhyCSstandsOut = () => {
  const points = [
    {
      title: "Complete Control and Privacy",
      description: "No sensitive data is required—everything is managed within your practice.",
      icon: "star_icon.svg", // Replace with the actual path to your star icon
    },
    {
      title: "All Essential Tools",
      description:
        "Schedule appointments, handle billing, manage records, and share prescriptions—all within one streamlined platform.",
      icon: "star_icon.svg", // Replace with the actual path to your star icon
    },
    {
      title: "Effortless Setup",
      description: "Ready to use in minutes, not months.",
      icon: "star_icon.svg", // Replace with the actual path to your star icon
    },
  ];

  return (
    <section className="why-cs-stands-out">
      <h2 className="why-cs-title">Why Clinix Sphere Stands Out</h2>
      <div className="why-cs-points">
        {points.map((point, index) => (
          <div key={index} className="why-cs-point">
            <img src={point.icon} alt="Star Icon" className="why-cs-icon" />
            <div className="why-cs-content">
              <h3 className="why-cs-heading">{point.title}</h3>
              <p className="why-cs-description">{point.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default WhyCSstandsOut;
