import React from "react";
import { FaTrash } from "react-icons/fa";
import "./readblog.css";
import { formatDate } from "../../components/common/formatDate";

const categories = [
  {
    name: "Healthcare Technology",
    value: 1,
  },
  { name: "Inventory and HR management", value: 2 },
  { name: "Telemedicine Innovations", value: 3 },
  { name: "Digital Transformation in Healthcare", value: 4 },
  { name: "Operational Efficiency", value: 5 },
  { name: "Patient Management", value: 6 },
];
const PreviewBlog = ({
  image,
  title,
  author,
  categoryId,
  content,
  setPreview,
}) => {
  //to find category of blog by its id
  const categoryById = (categoryId) => {
    return categories.find((category) => category.value === categoryId);
  };

  return (
    <>
      <div className="mx-auto p-6 bg-white rounded-lg text-black min-h-screen">
        {/* Blog Category  */}
        <div className="flex justify-between w-[1208px] mx-auto mb-6">
          <div className="text-[24px] text-[#797979] font-bold bg-opacity-50 bg-[#F0F0F0] px-4 py-1 rounded-3xl w-fit text-center overflow-clip text-nowrap">
            {categoryById(parseInt(categoryId)).name || "Uncategorized"}
          </div>
        </div>
        {/* Title and Date */}
        <div className="flex justify-between w-[1208px] mx-auto">
          <div className="w-[80%] text-left">
            <p className="text-[48px]">{title}</p>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex flex-row items-center gap-1 bg-[#C4C4C4] w-fit h-[33px] rounded-3xl p-1 px-2">
              <img
                src="https://png.pngtree.com/png-clipart/20230927/original/pngtree-man-avatar-image-for-profile-png-image_13001882.png"
                alt="p"
                className="w-[30px] h-[30px] rounded-full overflow-hidden"
              />
              <p className="font-bold">{author}</p>
            </div>
            <p className="text-[#999999]">{formatDate(Date.now())}</p>
          </div>
        </div>

        {/* Blog image */}
        <div className="w-[1208px] flex flex-col justify-center items-center mx-auto">
          <img
            src={image}
            alt="img"
            className="mx-auto w-[1016px] h-[682.11px] rounded-lg my-4"
          />
        </div>

        {/* Blog text content */}
        <div
          className="mx-auto flex flex-col my-10 text-left w-[1100px] p-4 text-[26px] blog-content"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>

        {/* Buttons */}
        <div className="w-[1208px] flex flex-row justify-center items-center gap-4 mt-6 mx-auto">
          <button
            className="flex flex-row gap-2 p-4 w-full max-w-[175px] shadow-xl hover:shadow-2xl h-[50px] items-center justify-center border border-[#03045E]  text-[#03045E] rounded-lg transition-colors"
            onClick={() => setPreview(false)}
          >
            Finish preview
          </button>
        </div>
      </div>
    </>
  );
};

export default PreviewBlog;
