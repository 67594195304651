import React from "react";
import { BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";

//Header and footer
import Header from "./components/header/header";
import Footer from "./components/footer/footer";

//functions
import ScrollToTop from "./components/common/ScrollToTop";
//hooks
import useFooterVisibility from "./hooks/useFooterVisibility";
import ScrollProgressBar from "./components/ScrollProgressBar/ScrollProgressBar";

//Routes
import BlogRoutes from "./routes/blogRoutes";
import authRoutes from "./routes/authRoutes";
import OtherRoutes from "./routes/otherRoutes";

const AppContent = () => {
  const showFooter = useFooterVisibility();

  return (
    <div className="App">
      <ScrollProgressBar />
      <ScrollToTop />
      <Header />

      <Routes>
        {/* Blog Pages  */}
        {BlogRoutes}

        {/* Auth Routes  */}
        {authRoutes}

        {/* Other routes  */}
        {OtherRoutes}
      </Routes>
      {showFooter && <Footer />}
    </div>
  );
};
function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
