import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaEdit, FaTrash } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import useApiRequest from "../../hooks/apiRequest.js";
import "./readblog.css";
import { formatDate } from "../../components/common/formatDate.js";
import Loader from "../../components/common/Loader.jsx";
import {
  ErrorToast,
  SuccessToast,
} from "../../components/common/toast/toast.js";
import BackButton from "../../components/common/BackButton.jsx";

const categories = [
  { name: "Healthcare Technology", value: 1 },
  { name: "Inventory and HR management", value: 2 },
  { name: "Telemedicine Innovations", value: 3 },
  { name: "Digital Transformation in Healthcare", value: 4 },
  { name: "Operational Efficiency", value: 5 },
  { name: "Patient Management", value: 6 },
];
const ReadBlog = () => {
  const navigate = useNavigate();
  const { apiRequest, loading } = useApiRequest();
  const { currentUser } = useSelector((state) => state.auth);
  const [blogContent, setBlogContent] = useState(null);
  const [showActionButtons, setShowActionButtons] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);

  const { blogId } = useParams();

  //fetch blog
  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const res = await apiRequest("GET", `/blog/get-blog-by-id/${blogId}`);

        const blog = res.data.blog;
        if (blog) {
          setBlogContent(blog);
        } else {
          throw new Error("Error in fetching blog.");
        }
      } catch (error) {
        ErrorToast(error.response?.data?.message || "Error in fetching blog.");
        console.error("Error in fetching blog: ", error);
      }
    };
    fetchBlog();
  }, [blogId]);

  //Logic to show update blog and delete blog button
  useEffect(() => {
    setShowActionButtons(
      currentUser && currentUser?.id === blogContent?.authorId?._id
    );
  }, [currentUser, blogContent]);

  //delete blog
  const handleDeleteBlog = async () => {
    try {
      const res = await apiRequest(
        "DELETE",
        `blog/delete-blog/${blogContent._id}`
      );
      SuccessToast(res.data?.message || "Blog deleted successfully.");

      setTimeout(() => {
        navigate("/blog");
      }, 100);
    } catch (error) {
      console.error("Failed to delete blog: ", error);
    }
  };
  //update blog
  const handleUpdateBlog = async (blogId) => {
    navigate(`/blog/updateblog/${blogId}`);
  };

  //to find category of blog by its id
  const categoryById = (categoryId) => {
    return categories.find((category) => category.value === categoryId);
  };

  if (loading) {
    return <Loader height="h-7 min-h-screen" width="w-7" />;
  }
  if (!blogContent) {
    return <p className="mt-10 text-xl">Blog not found</p>;
  }
  return (
    <>
      <div className="mx-auto p-6  bg-white rounded-lg text-[#03045E] min-h-screen">
        {/* Back button  */}
        <BackButton />
        {/* Blog Category  */}
        <div className="flex justify-between w-[1208px] mx-auto mb-6">
          <div className="text-[24px] text-[#797979] font-bold bg-opacity-50 bg-[#F0F0F0] px-4 py-1 rounded-3xl w-fit text-center overflow-clip text-nowrap">
            {categoryById(blogContent?.category).name || "Uncategorized"}
          </div>
        </div>

        {/* Title and Date  */}
        <div className="flex justify-between w-[1208px] mx-auto">
          <div className="w-[80%] text-left">
            <p className="text-[48px]">{blogContent?.title}</p>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex flex-row items-center gap-1 bg-[#C4C4C4] w-fit h-[33px] rounded-3xl p-1 px-2">
              <img
                src="https://png.pngtree.com/png-clipart/20230927/original/pngtree-man-avatar-image-for-profile-png-image_13001882.png"
                alt="p"
                className="w-[30px] h-[30px] rounded-full overflow-hidden"
              />
              <p className="font-bold">{blogContent?.author}</p>
            </div>
            <p className="text-[#999999]">
              {formatDate(blogContent?.createdAt)}
            </p>
          </div>
        </div>
        {/* blog content  */}
        <div className="w-[1208px] flex-wrap flex flex-col justify-center items-center mx-auto">
          {/* blog image  */}
          <img
            src={blogContent?.image}
            alt="img"
            className="mx-auto w-[1016px] h-[682.11px] rounded-lg my-4"
          />
        </div>
        {/* Blog text content  */}

        <div
          className="mx-auto flex flex-col my-10 text-left w-[1100px] p-4 text-[26px] blog-content"
          dangerouslySetInnerHTML={{ __html: blogContent?.content }}
        ></div>

        {/* Buttons  */}
        {showActionButtons && (
          <div className="w-[1208px] flex flex-row justify-center items-center gap-4 mt-6 mx-auto">
            <button
              className="flex flex-row gap-2 p-4 w-full max-w-[170px] h-[60px] border border-[#03045E] items-center justify-center  shadow-2xl text-[#03045E]] rounded-lg transition-colors"
              onClick={() => {
                handleUpdateBlog(blogContent._id);
              }}
            >
              Update Blog <FaEdit />
            </button>

            <button
              className="flex flex-row gap-2 p-4 w-full max-w-[170px] h-[60px] items-center justify-center bg-red-600 hover:bg-red-700 text-white rounded-lg transition-colors"
              onClick={() => {
                setShowPopUp(true);
              }}
            >
              <FaTrash />
              Delete Blog
            </button>
          </div>
        )}
        {/* Confirmation popup  */}
        {showPopUp && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-md shadow-xl max-w-sm w-full">
              <h3 className="text-lg font-semibold">Are you sure?</h3>
              <p className="mt-2">Do you want to proceed with this action?</p>
              <div className="mt-4 flex justify-end gap-4">
                <button
                  className="bg-gray-300 px-4 py-2 rounded-md hover:bg-gray-400"
                  onClick={() => {
                    setShowPopUp(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-800"
                  onClick={() => {
                    handleDeleteBlog(blogContent._id);
                  }}
                >
                  {loading ? "Deleting..." : "Confirm Delete"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ReadBlog;
