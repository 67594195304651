import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/common/Loader";
import useApiRequest from "../../hooks/apiRequest";
import { ErrorToast, SuccessToast } from "../../components/common/toast/toast";

const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const { apiRequest, loading } = useApiRequest();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Handle password input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "password") setPassword(value);
    if (name === "confirmPassword") setConfirmPassword(value);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if passwords match
    if (password !== confirmPassword) {
      ErrorToast("Passwords do not match");
      return;
    }
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(password)) {
      return ErrorToast(
        "Password must be at least 8 characters long, include one uppercase letter, one lowercase letter, one number, and one special character (e.g., @, $, !)"
      );
    }

    // Prepare data for API
    const data = { token, newPassword: password };

    // Send API request
    try {
      const res = await apiRequest("POST", "/userMain/resetPassword", data);
      SuccessToast(res.message || "Password changed.");
      navigate("/auth/signin");
    } catch (err) {
      ErrorToast(
        err.response?.data?.message || "An error occurred. Please try again."
      );
    }
  };

  return (
    <div className="bg-white p-4 min-h-screen items-center">
      <div className="flex justify-center">
        <div className="bg-white rounded-lg p-8 w-full max-w-4xl flex items-center">
          {/* Left Side: Form */}
          <div className="w-1/2">
            <h1 className="text-[32px] mb-2 text-left text-black font-[500]">
              Reset Password
            </h1>
            <p className="mb-4 text-[16px] text-black opacity-80 text-left w-[343px]">
              Please set a new password for your account.
            </p>

            <form onSubmit={handleSubmit} className="mb-4">
              <div>
                <label className="block text-black text-left font-[500] text-[14px] mb-2">
                  Create Password
                </label>
                <div className="flex justify-end">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Enter password"
                    value={password}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border rounded-xl text-black border-opacity-10"
                  />
                  <div className="text-black h-[20px] w-[20px] text-lg absolute mr-2 mt-2">
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </button>
                  </div>
                </div>
              </div>
              <div>
                <label className="block text-black text-left font-[500] text-[14px] mb-2">
                  Re-enter Password
                </label>
                <div className="flex justify-end">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    placeholder="Enter password"
                    value={confirmPassword}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border rounded-xl text-black border-opacity-10"
                  />
                  <div className="text-black h-[20px] w-[20px] text-lg absolute mr-2 mt-2">
                    <button
                      type="button"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                    </button>
                  </div>
                </div>
              </div>

              <button
                type="submit"
                className="w-full bg-[#3D74FF] text-white py-2 rounded-2xl font-[700] my-4"
              >
                {loading ? <Loader marginY="1" /> : <p>Submit</p>}
              </button>
            </form>

            <p className="mt-6 text-left text-[#77707F]">
              Remembered password?{" "}
              <a href="/auth/signin" className="text-blue-500 opacity-100">
                Sign in to your account
              </a>
            </p>
          </div>

          {/* Right Side: Image */}
          <div className="w-1/2 flex justify-center items-center pl-32">
            <img
              src="https://res.cloudinary.com/djwfu7z21/image/upload/v1730902482/auth-1_mh9pfo.png"
              alt="SignIn"
              className="w-[30rem] h-[30rem] object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
