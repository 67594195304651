import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const useFooterVisibility = () => {
  const location = useLocation();
  const [showFooter, setShowFooter] = useState(true);

  useEffect(() => {
    const pathsWithoutFooter = ["/blog", "/blog/createblog", "/blog/readblog"]; // Add more paths here
    setShowFooter(!pathsWithoutFooter.includes(location.pathname));
  }, [location.pathname]);

  return showFooter;
};

export default useFooterVisibility;
