import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  ErrorToast,
  SuccessToast,
} from "../../components/common/toast/toast.js";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/common/BackButton.jsx";

const Contact = () => {
  const subjects = [
    "General Inquiry",
    "Healthcare Services",
    "Insurance and Costs",
  ];
  const [selected, setSelected] = useState(subjects[0]);
  const initialFormData = {
    firstname: "",
    lastname: "",
    email: "",
    phoneno: "",
    subject: "General Inquiry",
    message: "",
  };
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormData);

  const onChange = (field, value) => {
    // If phone number is being updated, we make sure to only allow digits.
    if (field === "phoneno") {
      // Ensure the phone number is 10 digits (only numeric input).
      const numericValue = value.replace(/[^\d]/g, ""); // Remove non-numeric characters
      if (numericValue.length <= 10) {
        setFormData((prevState) => ({
          ...prevState,
          [field]: numericValue, // Store only digits in phoneno field
        }));
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    }
  };

  const handleSubmitForm = async (event) => {
    event.preventDefault(); // Prevent default form submission
    if (
      formData.firstname === "" ||
      formData.lastname === "" ||
      formData.email === "" ||
      formData.phoneno === "" ||
      formData.message === ""
    ) {
      ErrorToast("Please fill all the fields");
    } else {
      try {
        // Convert phoneno to an integer when sending the request
        const data = await axios.post(`/v1/api/contact/save-contact`, {
          ...formData,
          phoneno: parseInt(formData.phoneno), // Convert phone number to integer
        });
        console.log("Contact saved successfully:", data);

        // Show success notification
        SuccessToast(
          data.data?.message || "Your message has been sent successfully!"
        );

        // Reset formData to its initial state
        setFormData(initialFormData);
      } catch (error) {
        console.error("Error saving contact:", error);
        // Show error notification
        ErrorToast(
          error.response?.data?.message ||
            "There was an error sending your message. Please try again."
        );
      }
    }
  };

  return (
    <div className="text-[#03045E] bg-[#EAE9E9] pb-12">
      <div className="ml-20 text-left flex gap-[525px]">
        <BackButton />
      </div>
      <div className="w-full text-center flex flex-col justify-center">
        <p className="text-4xl font-extrabold font-sans text-center">
          Contact Us
        </p>
        <p className="mt-5 text-lg ">
          Any question or remarks? Just write a message!
        </p>
      </div>
      <div className="bg-white w-[1050px] rounded-xl flex p-2 mx-auto mt-4">
        <div className="bg-[#EAE9E9] h-[500px] rounded">
          <h1 className="mx-auto w-[400px] text-3xl font-bold mt-7 flex justify-center mb-28">
            Contact Information
          </h1>
          <div className="flex ml-16 gap-3 mb-3">
            <img
              className="h-5 w-5"
              src="https://res.cloudinary.com/djwfu7z21/image/upload/v1731067380/Phone_qnprzg.svg"
              alt="img"
            />
            <p className="">+91 4648365839</p>
          </div>
          <div className="flex ml-16 gap-3 mb-3">
            <img
              className="h-5 w-5"
              src="https://res.cloudinary.com/djwfu7z21/image/upload/v1731067380/mail_mxkiy2.svg"
              alt="img"
            />
            <p className="">nsbdsjd@sahjb</p>
          </div>
          <div className="flex ml-16 gap-3">
            <img
              className="h-5 w-5"
              src="https://res.cloudinary.com/djwfu7z21/image/upload/v1731067380/location_nkkk1d.svg"
              alt="img"
            />
            <p className="">245 sbalkb adjhcka adjhvcd</p>
          </div>
        </div>
        <div className="mt-12 mx-auto">
          <div className="flex gap-10">
            <div>
              <p>First Name</p>
              <input
                className="border-none focus:outline-none h-10 w-64"
                type="text"
                onChange={(e) => onChange("firstname", e.target.value)}
              />
              <div className="h-[1px] w-64 border border-[#03045E]"></div>
            </div>

            <div>
              <p>Last Name</p>
              <input
                className="border-none focus:outline-none h-10 w-64"
                type="text"
                value={formData.lastname}
                onChange={(e) => onChange("lastname", e.target.value)}
              />
              <div className="h-[1px] w-64 border border-[#03045E]"></div>
            </div>
          </div>
          <div className="flex gap-10 mt-8">
            <div className="">
              <p>Email</p>
              <input
                className="border-none focus:outline-none h-10 w-64"
                type="email"
                onChange={(e) => onChange("email", e.target.value)}
              />
              <div className="h-[1px] w-64 border border-[#03045E]"></div>
            </div>

            <div>
              <p>Phone Number</p>
              <input
                className="border-none focus:outline-none h-10 w-64"
                type="text"
                value={formData.phoneno}
                onChange={(e) => onChange("phoneno", e.target.value)} // Handle onChange for phone number
                maxLength="10" // Limit input length to 10 characters
              />
              <div className="h-[1px] w-64 border border-[#03045E]"></div>
            </div>
          </div>
          <p className="w-full text-xl font-bold mt-8 mb-3">Select Subject?</p>
          <div className="flex space-x-6  border-blue-300 pl-6">
            {subjects.map((subject, index) => (
              <div
                key={index}
                onClick={() => {
                  setSelected(subject);
                  onChange("subject", subject);
                }}
                className={`flex items-center text-sm cursor-pointer space-x-2 ${
                  selected === subject
                    ? "text-blue-900 font-semibold"
                    : "text-gray-400"
                }`}
              >
                <span
                  className={`h-4 w-4 rounded-full  border-2 ${
                    selected === subject
                      ? "bg-blue-900 border-blue-900"
                      : "border-gray-400"
                  }`}
                />
                <span>{subject}</span>
              </div>
            ))}
          </div>
          <p className="mt-5">Message</p>
          <input
            type="text"
            className="border-none focus:outline-none h-10 w-[450px]"
            placeholder="Write your message"
            value={formData.message}
            onChange={(e) => onChange("message", e.target.value)}
          />
          <div className="h-[1px] w-[450px] border border-[#03045E]"></div>
          <div className="flex justify-center mt-7">
            <button
              onClick={handleSubmitForm}
              className="h-12 w-40 bg-white text-[#03045E] font-semibold rounded-lg shadow-md hover:bg-indigo-50 focus:outline-none focus:ring-2  transition ease-in-out duration-200"
            >
              Send Message
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
