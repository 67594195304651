import React from "react";

const Loader = ({
  height = "h-5", // default height of the spinner
  width = "w-5", // default width of the spinner
  text = "", // default loading text
  bgOpacity = "bg-opacity-50", // default background opacity
  spinnerColor = "currentColor", // default color for the spinner
  textColor = "text-black", // default text color
  textSize = "text-sm", // default text size
  marginY = "my-28",
}) => {
  return (
    <div className={`flex items-center justify-center ${bgOpacity} ${marginY}`}>
      <div className="flex items-center">
        <svg
          className={`animate-spin ${height} ${width} mr-3`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke={spinnerColor}
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke={spinnerColor}
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill={spinnerColor}
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
          ></path>
        </svg>
        <span className={`${textColor} ${textSize}`}>{text}</span>
      </div>
    </div>
  );
};

export default Loader;
