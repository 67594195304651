import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Popup from "../common/popup/popup";
import "./hero.css";
import { formToJSON } from "axios";

import useApiRequest from "../../hooks/apiRequest"; //use useApiRequest hook to get loading state and apiRequest loader
import { ErrorToast, SuccessToast } from "../common/toast/toast";

const Hero = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  //loading will be set to true whenever we make api req, set false when its resolved or give error.
  const { loading, apiRequest } = useApiRequest();
  const navigate = useNavigate();  // Initialize the navigation hook

  const handleButtonClick = () => {
    navigate('/contact');  // Navigate to the contact page
  }
  useEffect(() => {
    // Listen for scroll events to determine if the page is scrolled
    const handleScroll = () => {
      if (window.scrollY > 50) {
        // Adjust the value if needed
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleRequestDemoClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleSubmitForm = async (formData) => {
    // API request to save the contact details using the API function
    try {
      let jsonData = formToJSON(formData); //convert formData to json for axios req
      const data = await apiRequest("POST", `/contact/save-contact`, jsonData); //use loader to make api request

      SuccessToast("Your message has been sent successfully!");

      setIsPopupOpen(false); // Close the popup on successful submit
    } catch (error) {
      ErrorToast("There was an error sending your message. Please try again.");

      console.error("Error saving contact:", error);
    }
  };

  const fields = [
    { name: "name", label: "Name", type: "text" },
    {
      name: "phone",
      label: "Phone",
      type: "tel",
      pattern: "^[0-9]{10}$",
      customErrorMessage: "Please enter a 10 digit phone number", // Custom message
    },
    {
      name: "email",
      label: "Email",
      type: "email",
      pattern: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$", // Email validation pattern
      customErrorMessage: "Please provide a valid email Id", // Custom message for email
    },
    { name: "query", label: "Query", type: "textarea" },
  ];

  const requiredFields = ["name", "phone"];

  return (
    <div className="hero">
      {/* Text Section */}
      <div className="hero-content">
        <h1 className="hero-heading">
          Transform Your Patient Interactions with Complete Control and Privacy
        </h1>
        <p className="hero-subheading">
          Clinix Sphere empowers healthcare providers to create their own
          secure digital space, connecting directly with patients without
          third-party involvement.
        </p>
      </div>
      {/* Hero Image Section */}
  <div className="hero-image-container">
    {/* Background for Hero Image */}
    <div className="hero-image-background">
      <img src="/Hero_img_bg.svg" alt="Hero Background" />
    </div>

    {/* Main Hero Image */}
    <div className="hero-image">
      <img src="/Hero_img.svg" alt="Main Hero" />
    </div>
  </div>

  {/* CTA Button Section */}
  <div className="cta-button-container">
    <button className="cta-button" onClick={handleButtonClick}>Get Started in 30 Minutes</button>
  </div>
</div>

      
  );    
};

export default Hero;
