import { Route } from "react-router-dom";
import NotFound from "../pages/notFound/notFound";
import AboutUs from "../pages/aboutUs/aboutUs";
import Home from "../pages/home/home";
import Contact from "../pages/contact/contact";

const OtherRoutes = (
  <>
    <Route path="*" element={<NotFound />} />;
    <Route path="/" element={<Home />} />;
    <Route path="/about" element={<AboutUs />} />;
    <Route path="/contact" element={<Contact />} />;
  </>
);

export default OtherRoutes;
