//This function takes current user from local storage redux root persist as input. And returns wether that user is admin or not.
//This is mainly used to display ui/ux based on if user is admin or not.

//This is not used for protecting Routes.

export const isUserAdmin = (currentUser) => {
  if (!currentUser) return false;
  try {
    return currentUser.isAdmin;
  } catch (error) {
    console.error("Error checking user for admin: ", error);
    return false;
  }
};
