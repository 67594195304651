import React, { useState, useEffect } from "react";
import "./aboutUs.css";
import "@fortawesome/fontawesome-free/css/all.min.css"; // Importing FontAwesome icons

const AboutUs = () => {
  const [isModalOpen, setModalOpen] = useState(false); // State to manage modal visibility
  // Function to open the modal
  const handleViewMore = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  // Function to detect if the click is outside the modal and close it
  const handleOutsideClick = (e) => {
    // Close modal if click is outside the modal-content
    if (e.target.classList.contains("modal-overlay")) {
      closeModal();
    }
  };

  useEffect(() => {
    // When modal is open, disable background scrolling and add click event listener
    if (isModalOpen) {
      document.body.classList.add("no-scroll"); // Disable background scroll
      window.addEventListener("click", handleOutsideClick);
    } else {
      document.body.classList.remove("no-scroll"); // Enable background scroll
      window.removeEventListener("click", handleOutsideClick);
    }

    // Cleanup the scroll-lock when component unmounts
    return () => {
      document.body.classList.remove("no-scroll"); // In case modal is still open
      window.removeEventListener("click", handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]); // Effect runs whenever `isModalOpen` state changes

  return (
    <div className="about-us-container">
      <section className="about-us-section">
        <h1>About Us</h1>
        <p className="about-description">
          Clinix Sphere offers a platform that streamlines administrative
          processes, allowing healthcare providers to focus on delivering
          superior care. We provide customized backend solutions for clinics and
          hospitals.
        </p>
        <div className="view-more-wrapper centered">
          <button className="view-more-btn" onClick={handleViewMore}>
            View More
            <span className="circle">
              <i className="fa fa-arrow-right"></i>
            </span>
          </button>
        </div>
      </section>

      {/* Modal */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <i class="fa-solid fa-xmark close-btn" onClick={closeModal}></i>
            </div>
            <div className="modal-body scrollable-content">
              <h3>About Clinix Sphere</h3>
              <p>
                Clinix Sphere is a healthcare technology leader, providing
                customized backend solutions for clinics and hospitals. Our
                platform enhances operational efficiency and patient care
                through tailored tools for patient management, telemedicine,
                inventory, and HR. We offer each healthcare facility a dedicated
                domain, giving them full control over their digital operations,
                ensuring a seamless and personalized experience. By streamlining
                administrative processes, we empower healthcare providers to
                focus on delivering superior care and optimizing their
                operations.
              </p>
              {/* Converting the Patient Interaction Into Bullet Points using unordered lists */}
              <h5>Patient Interaction:</h5>
              <ul className="interaction-list">
                <li>
                  Patients receive branded communications from their healthcare
                  facility
                </li>
                <li>Communication channels include SMS, email, and WhatsApp</li>
                <li>
                  All interactions carry the healthcare provider's branding and
                  identity
                </li>
                <li>
                  Patients can access services without directly interfacing with
                  Clinix Sphere
                </li>
                <li>
                  Services include appointment scheduling, telemedicine
                  sessions, and lab result viewing
                </li>
                <li>
                  Patients receive links for specific actions (e.g., scheduling
                  follow-ups, joining virtual consultations)
                </li>
                <li>
                  The process ensures convenience and accessibility for managing
                  healthcare needs
                </li>
                <li>
                  White-label approach maintains a direct connection between
                  patients and their healthcare provider
                </li>
                <li>
                  Consistent branding reinforces a unified and professional
                  experience
                </li>
                <li>
                  This method builds trust and ensures a smooth patient
                  experience
                </li>
                <li>
                  Patients benefit from seamless integration of services without
                  awareness of the underlying platform
                </li>
              </ul>
              <p className="interaction-paragraph">
                These points highlight the patient-centric approach of Clinix
                Sphere, emphasizing the seamless, branded experience patients
                receive through their healthcare provider's use of the platform.
              </p>

              <h5>Healthcare Facility Interaction:</h5>
              <ul className="interaction-list">
                <li>
                  Healthcare facilities interact directly with Clinix Sphere
                  through a dedicated domain
                </li>
                <li>
                  Each clinic or hospital has full ownership and control over
                  digital operations
                </li>
                <li>
                  The platform creates a sense of autonomy in service management
                </li>
                <li>
                  Extensive set of tools available to streamline operations and
                  enhance efficiency
                </li>
                <li>
                  Single, user-friendly interface for accessing all features
                </li>
                <li>
                  Features include appointment management, patient records,
                  billing, inventory control
                </li>
                <li>
                  Prescription management and telemedicine services are
                  integrated
                </li>
                <li>
                  Platform enables effective management of day-to-day tasks and
                  long-term operational needs
                </li>
                <li>
                  Helps facilities oversee patient care and ensure regulatory
                  compliance
                </li>
                <li>Designed to optimize workflow and improve communication</li>
                <li>Reduces administrative burdens for healthcare providers</li>
                <li>Customizable platform that scales with facility needs</li>
                <li>Allows focus on delivering high-quality patient care</li>
                <li>Clinix Sphere handles backend complexities</li>
              </ul>
              <p className="interaction-paragraph">
                These points outline how patients interact with healthcare
                services via Clinix Sphere’s white-label approach, ensuring
                convenience and familiarity without direct engagement with the
                platform itself.
              </p>
              <p className="interaction-paragraph">
                <strong>
                  This dual interaction model separates patient and
                  facility-facing experiences, which is a key aspect of what
                  makes Clinix Sphere’s solution unique. It allows patients to
                  interact with their healthcare provider seamlessly, while
                  giving facilities the autonomy and technological support
                  needed to improve care delivery and operational performance.
                </strong>
              </p>
            </div>
          </div>
        </div>
      )}

      <section className="interactive-models-section">
        <h2>Interactive Models</h2>
        <hr className="line-below-heading" />

        <div className="interaction-model">
          <div className="interaction-content">
            <h3>Patient Interaction:</h3>
            <hr className="line-below-subheading" />
            <ul>
              <li>
                Communications are branded under the healthcare provider,
                ensuring a seamless, familiar experience.
              </li>
              <li>
                Patients interact with services provided by their healthcare
                facility through Clinix Sphere, but not the platform itself.
              </li>
            </ul>
          </div>
          <div className="interaction-icon-wrapper">
            <div className="interaction-icon">
              <i className="fa fa-heartbeat icon"></i>{" "}
              {/* Update icon if necessary */}
            </div>
            <div className="view-more-wrapper">
              <button className="view-more-btn" onClick={handleViewMore}>
                View More
                <span className="circle">
                  <i className="fa fa-arrow-right"></i>
                </span>
              </button>
            </div>
          </div>
        </div>

        <hr className="divider" />

        <div className="interaction-model">
          <div className="interaction-content">
            <h3>Healthcare Interaction:</h3>
            <hr className="line-below-subheading" />
            <ul>
              <li>
                Facilities access Clinix Sphere through a dedicated domain,
                allowing full control over digital operations.
              </li>
              <li>
                Key tools include appointment management, patient records,
                billing, inventory, prescription management, and telemedicine.
              </li>
            </ul>
          </div>
          <div className="interaction-icon-wrapper">
            <div className="interaction-icon">
              <i className="fa fa-hospital icon"></i>{" "}
              {/* Update icon if necessary */}
            </div>
            <div className="view-more-wrapper">
              <button className="view-more-btn" onClick={handleViewMore}>
                View More
                <span className="circle">
                  <i className="fa fa-arrow-right"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
