import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import useApiRequest from "../../hooks/apiRequest";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import {
  signUpFailure,
  signUpStart,
  signUpSuccess,
} from "../../redux/auth/authSlice";
import { jwtDecode } from "jwt-decode";
import Loader from "../common/Loader";
const GoogleAuth = () => {
  const { apiRequest, loading } = useApiRequest();
  const dispatch = useDispatch();
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const userInfo = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
            },
          }
        );
        dispatch(signUpStart());
        // Send token to backend to verify and get JWT
        const response = await apiRequest("POST", "/userMain/googleLogin", {
          userInfo: userInfo,
        });
        // Store the JWT token in cookies
        Cookies.set("clinix-auth-token", response.jwt);
        const decoded = jwtDecode(response.jwt);

        const data = { name: decoded.name, email: decoded.email };
        dispatch(signUpSuccess(data));
      } catch (error) {
        console.error("Error verifying Google token:", error);
        dispatch(signUpFailure());
      }
    },
    onError: (error) => console.log("Login Failed:", error),
  });
  return (
    <>
      {loading ? (
        <Loader marginY="1" />
      ) : (
        <button
          onClick={() => login()}
          className="w-full bg-[#F4F7FF] text-gray-700 py-2 mt-4 rounded-lg flex justify-center items-center"
          disabled={loading}
        >
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/300px-Google_%22G%22_logo.svg.png"
            alt="Google Icon"
            className="w-6 h-6 mr-2"
          />
          Continue with Google
        </button>
      )}
    </>
  );
};
export default GoogleAuth;
