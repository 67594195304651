import React from "react";
import "./whyChooseUs.css";

const WhyChooseUs = () => {
  const features = [
    {
      title: "Complete Privacy & Control",
      description:
        "Your clinic, lab, or pharmacy manages all data and patient interactions directly, with no third-party interference.",
    },
    {
      title: "Instant Setup",
      description:
        "Launch your fully branded app in just 30 minutes, eliminating months of development.",
    },
    {
      title: "Seamless Medical Network",
      description:
        "Connect effortlessly with patients, labs, and chemists to streamline continuity of care.",
    },
  ];

  return (
    <section className="why-choose-us">
      <h2 className="why-choose-us__title">Why Clinix Sphere?</h2>
      <div className="why-choose-us__features">
        {features.map((feature, index) => (
          <div
            key={index}
            className="why-choose-us__feature"
          >
            <div className="why-choose-us__icon"></div>
            <div>
              <h3 className="why-choose-us__feature-title">{feature.title}</h3>
              <p className="why-choose-us__feature-description" style={{
              ...(index === 1 && { marginTop: "2rem" }), // Apply margin-bottom conditionally
            }}>
                {feature.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default WhyChooseUs;
