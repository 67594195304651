import React, { useState } from "react";
import useApiRequest from "../../hooks/apiRequest";
import { ErrorToast, SuccessToast } from "../../components/common/toast/toast";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/common/Loader";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const { apiRequest, loading } = useApiRequest();

  //handle change in email
  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    setEmail((prev) => ({ ...prev, [name]: value }));
  };

  //handle submission of email
  const handelSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = email;
      const res = await apiRequest("POST", "/userMain/resetPasswordMail", data);
      SuccessToast(res.message);
    } catch (error) {
      console.error("Error in rest password: ", error);
      ErrorToast(error.response?.data?.message || "Error in reset password.");
    }
  };

  return (
    <div className="bg-white p-4 min-h-screen items-center">
      <div className=" flex justify-center ">
        <div className="bg-white  rounded-lg p-8 w-full max-w-4xl flex items-center">
          {/* Left Side: Form */}
          <div className="w-1/2">
            <h1 className="text-[32px] mb-2 text-left text-black font-[500] ">
              Forgot Password
            </h1>

            {/* Form for Email */}

            <div className="flex flex-col gap-8">
              <p className="mb-4 text-[16px] text-black opacity-80 text-left w-[343px]">
                Enter the email address registered with your account.
              </p>

              <form
                onSubmit={handelSubmit}
                className="mb-4 flex flex-col gap-10"
              >
                <label className="block text-black text-left font-[500] text-[14px] mb-2">
                  Email address
                </label>
                <input
                  onChange={handleEmailChange}
                  name="email"
                  type="email"
                  placeholder="Enter your email"
                  className="w-full px-4 py-2 border rounded-xl text-black border-opacity-10 "
                />

                <button
                  type="submit"
                  className="w-full bg-[#3D74FF] text-white py-2 rounded-2xl font-[700]"
                  disabled={loading}
                >
                  {loading ? <Loader marginY="1" /> : <p>Submit</p>}
                </button>
              </form>
            </div>

            <p className="mt-6 text-left text-[#77707F]">
              Remembered password?{" "}
              <button
                type="button"
                onClick={() => {
                  navigate("/auth/signin");
                }}
                className="text-blue-500 opacity-100"
              >
                Signin to your account
              </button>
            </p>
          </div>

          {/* Right Side: Image */}
          <div className="w-1/2 flex justify-center items-center pl-32">
            <img
              src="https://res.cloudinary.com/djwfu7z21/image/upload/v1730902482/auth-1_mh9pfo.png"
              alt="SignIn"
              className="w-[30rem] h-[30rem] object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
