import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="mb-6 text-left">
        <button
          className="w-14 h-14 bg-blue-800 text-white font-[700] text-[2rem] hover:bg-blue-700 transition-colors rounded-full flex items-center justify-center mt-6"
          onClick={() => navigate(-1)}
        >
          <FaArrowLeft />
        </button>
      </div>
    </div>
  );
};

export default BackButton;
