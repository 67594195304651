import React from "react";
import { Route } from "react-router-dom";
import SignUp from "../pages/auth/signUp";
import SignIn from "../pages/auth/signIn";
import ForgotPassword from "../pages/auth/forgotPassword";
import ResetPassword from "../pages/auth/resetPassword";

const AuthRoutes = (
  <>
    <Route path="/auth/signup" element={<SignUp />} />
    <Route path="/auth/signin" element={<SignIn />} />
    <Route path="/auth/forgotpassword" element={<ForgotPassword />} />
    <Route path="/reset/:token" element={<ResetPassword />} />
  </>
);

export default AuthRoutes;
