import { Route } from "react-router-dom";
import Blog from "../pages/blog/blog";
import CreateBlog from "../pages/blog/createBlog";
import ReadBlog from "../pages/blog/readBlog";
import UpdateBlog from "../pages/blog/updateBlog";
import ProtectAdminRoute from "../components/ProtectAdminRoute";

const BlogRoutes = (
  <>
    <Route path="/blog" element={<Blog />} />
    <Route path="/blog/readblog/:blogId" element={<ReadBlog />} />
    <Route element={<ProtectAdminRoute />}>
      <Route path="/blog/createblog" element={<CreateBlog />} />
      <Route path="/blog/updateblog/:blogId" element={<UpdateBlog />} />
    </Route>
  </>
);

export default BlogRoutes;
