import { configureStore, combineReducers } from "@reduxjs/toolkit";
import blogReducer from "./blog/blogSlice.js";
import authReducer from "./auth/authSlice.js";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // This uses localStorage by default

const rootReducer = combineReducers({
  blog: blogReducer,
  auth: authReducer,
});

const persistConfig = {
  key: "root", // The key for the persisted state in storage
  storage, // Storage type, in this case, it's localStorage
  version: 1, // Version of your persisted state
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }), // To handle non-serializable data like functions or classes in state
});

export const persistor = persistStore(store); // Creates the persistor to manage the persistence
