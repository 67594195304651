import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { signOut } from "../redux/auth/authSlice";
import { ErrorToast } from "./common/toast/toast";

const isTokenValidForAdmin = (token) => {
  if (!token) return false;
  try {
    const decoded = jwtDecode(token);
    return decoded.exp > Date.now() / 1000 && decoded.isAdmin === true; // Token is valid if exp is in the future and isAdmin in token is true
  } catch (error) {
    console.error("Token decoding failed:", error);
    return false;
  }
};
const isCurrentUserValidForAdmin = (currentUser) => {
  if (!currentUser) return false;
  try {
    return currentUser.isAdmin;
  } catch (error) {
    console.error("Error in currentUser: ", error);
    return false;
  }
};

const ProtectAdminRoute = () => {
  const { currentUser } = useSelector((state) => state.auth);
  const token = Cookies.get("clinix-auth-token");

  // Check if token is valid
  const isValidTokenForAdmin = isTokenValidForAdmin(token);
  const isValidCurrentUserForAdmin = isCurrentUserValidForAdmin(currentUser);

  // If token is invalid, sign out the user and clear the token
  if (
    (token && !isValidTokenForAdmin) ||
    (currentUser && !isValidCurrentUserForAdmin)
  ) {
    ErrorToast("Only admins can access this page.");
  }

  // Redirect to login if there's no valid user or token
  if (!isValidCurrentUserForAdmin || !isValidTokenForAdmin) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default ProtectAdminRoute;
