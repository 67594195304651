import React from "react";
import Hero from "../../components/hero/hero";
import WhyChooseUs from "../../components/whyChooseUs/whyChooseUs";
import KeyFeatures from "../../components/features/keyFeatures";
import HowItWorks from "../../components/howItWorks/howItWorks";
import AdditionalCta from "../../components/additionalCta/additionalCta";
import WhyCSstandsOut from "../../components/WhyCSstandsOut/WhyCSstandsOut";

const Home = () => {
  return (
    <div>
      <Hero />
      <WhyChooseUs />
      <KeyFeatures />
      <HowItWorks />
      <WhyCSstandsOut />
      <AdditionalCta />
    </div>
  );
};

export default Home;
