import React, { useEffect, useState } from "react";
import { FaCheck, FaEye, FaEyeSlash } from "react-icons/fa";
import OTPInput from "react-otp-input";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import GoogleAuth from "../../components/auth/signUpWithGoogle";
import useApiRequest from "../../hooks/apiRequest";
import { ErrorToast, SuccessToast } from "../../components/common/toast/toast";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import {
  signUpStart,
  signUpSuccess,
  signUpFailure,
} from "../../redux/auth/authSlice";

import Cookies from "js-cookie";

//country codes data
import { countryCodes } from "../../components/common/countryCodes";
import Loader from "../../components/common/Loader";

const SignUp = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { apiRequest, loading } = useApiRequest();

  //redux
  const { currentUser } = useSelector((state) => state.auth);

  //check token validation
  const token = Cookies.get("clinix-auth-token");
  const isTokenValid = (token) => {
    if (!token) return false;
    try {
      const decoded = jwtDecode(token);
      return decoded.exp > Date.now() / 1000; // Token is valid if exp is in the future
    } catch (error) {
      console.error("Token decoding failed:", error);
      return false;
    }
  };

  // State for form handling
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [otp, setOtp] = useState("");
  const [signUpSuccessful, setSignUpSuccessful] = useState(false);
  const [tab, setTab] = useState("email"); // Default tab to "email"
  const [selectedCode, setSelectedCode] = useState(
    countryCodes.countries[0].code
  ); //country code

  //Form data to signup with email and phone
  const [signUpWithEmailFormData, setSignUpWithEmailFormData] = useState({
    name: "",
    password: "",
    email: "",
    signupMethod: "",
  });
  const [signUpWithPhoneFormData, setSignUpWithPhoneFormData] = useState({
    name: "",
    phoneNumber: "",
    signupMethod: "",
  });

  // Update tab based on URL parameters
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const tabFromUrl = urlParams.get("tab");
    if (tabFromUrl) setTab(tabFromUrl);
  }, [location.search]);

  //for email on change set values
  const handleInputChangeForEmail = (e) => {
    const { name, value } = e.target;
    setSignUpWithEmailFormData((prev) => ({
      ...prev,
      [name]: value,
      signupMethod: "email",
    }));
  };

  //handle signup with email
  const handleSignUpWithEmail = async (e) => {
    e.preventDefault();

    const { name, email, password, confirmPassword, terms } = e.target.elements;

    // Basic field validation
    if (
      !name.value ||
      !email.value ||
      !password.value ||
      !confirmPassword.value
    ) {
      return ErrorToast("Please fill all the required fields");
    }

    // Terms and Conditions acceptance validation
    if (!terms.checked) {
      return ErrorToast("Please accept the Terms and Conditions");
    }

    // Email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email.value)) {
      return ErrorToast("Please enter a valid email address");
    }

    // Password and confirmPassword match validation
    if (password.value !== confirmPassword.value) {
      return ErrorToast("Passwords do not match");
    }

    // Password strength validation (at least one symbol, one lowercase, one uppercase, and one number)
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(password.value)) {
      return ErrorToast(
        "Password must be at least 8 characters long, include one uppercase letter, one lowercase letter, one number, and one special character (e.g., @, $, !)"
      );
    }

    try {
      dispatch(signUpStart());
      const res = await apiRequest(
        "POST",
        "/userMain/signup",
        signUpWithEmailFormData
      );

      const token = res.data.jwt; //take token from response

      // Check if token exists in the response
      if (token) {
        try {
          const decoded = jwtDecode(token); //decode token to extract information to place it in redux persist
          const data = {
            id: decoded.id,
            name: decoded.name,
            email: decoded.email,
            isAdmin: decoded.isAdmin,
          };

          dispatch(signUpSuccess(data)); // Dispatch decoded data to Redux

          Cookies.set("clinix-auth-token", token); // Place token in cookies

          SuccessToast("SignUp successful");
          setSignUpSuccessful(true);
        } catch (error) {
          console.error("Token decoding failed:", error);
          ErrorToast("Sign-up failed due to token error.");
          dispatch(signUpFailure());
        }
      }
    } catch (error) {
      console.error(error);
      dispatch(signUpFailure());
      ErrorToast(error.response?.data?.message || "Error in sign up");
    }
  };

  //for phone on change set values
  const handleInputChangeForPhone = (e) => {
    const { name, value } = e.target;
    setSignUpWithPhoneFormData((prev) => ({
      ...prev,
      [name]: name === "phoneNumber" ? `${selectedCode}${value}` : value,
      signupMethod: "mobile",
    }));
  };

  //send otp with phone number
  const handleSignUpWithPhone = async (e) => {
    e.preventDefault();

    const { name, phoneNumber, terms } = e.target.elements;

    // Basic field validation
    if (!name.value || !phoneNumber.value) {
      return ErrorToast("Please fill all the required fields");
    }

    // Terms and Conditions acceptance validation
    if (!terms.checked) {
      return ErrorToast("Please accept the Terms and Conditions");
    }

    // Phone number format validation for a 10-digit number
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(phoneNumber.value)) {
      return ErrorToast("Please enter a valid 10-digit phone number");
    }

    try {
      const res = await apiRequest(
        "POST",
        "/userMain/signup",
        signUpWithPhoneFormData
      );
      SuccessToast(res?.data?.message || "Otp sent");
      navigate(
        `/auth/signup?tab=otpsent&phoneNumber=${selectedCode}${phoneNumber.value}&name=${name.value}`
      );
    } catch (error) {
      console.error(error);
      ErrorToast(error.reponse?.data?.message || "Error in sign up");
    }
  };

  // Handle OTP submission
  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    const urlParams = new URLSearchParams(location.search);
    const phoneNumberFromUrl = urlParams.get("phoneNumber");

    const data = {
      phoneNumber: `+${phoneNumberFromUrl.trim()}`,
      otp: otp.toString(),
    };

    try {
      dispatch(signUpStart());
      const res = await apiRequest("POST", "/userMain/verify-otp", data);

      // Decoding token to get data and store data in redux persist
      const token = res.data.token;
      // Check if token exists in the response
      if (token) {
        try {
          const decoded = jwtDecode(token); //decode token to extract information to place it in redux persist
          const data = {
            id: decoded.id,
            name: decoded.name,
            email: decoded.email,
            isAdmin: decoded.isAdmin,
          };

          dispatch(signUpSuccess(data)); // Dispatch decoded data to Redux

          Cookies.set("clinix-auth-token", token); // Place token in cookies

          SuccessToast("SignUp successful");
          setSignUpSuccessful(true);
        } catch (error) {
          console.error("Token decoding failed:", error);
          ErrorToast("Sign-up failed due to token error.");
          dispatch(signUpFailure());
        }
      }
    } catch (error) {
      console.error(error);
      ErrorToast(error.response?.data?.message || "Error in OTP verification");
    }
  };

  //handle resend otp
  const handleResendOtp = async () => {
    const urlParams = new URLSearchParams(location.search);
    const phoneNumberFromUrl = urlParams.get("phoneNumber");
    const name = urlParams.get("name");
    const data = {
      name,
      phoneNumber: "+" + phoneNumberFromUrl.trim(),
      loginMethod: "mobile",
    };

    try {
      const res = await apiRequest("POST", "/userMain/login", data);
      SuccessToast(res?.data?.message || "Otp sent");
    } catch (error) {
      console.error(error);
      ErrorToast(error.response?.data?.message || "Error in sending otp");
    }
  };

  //Signup with email component
  const signUpWithEmail = (
    <div>
      <p className="mb-4 text-[16px] text-black opacity-80 text-left">
        Enter your credentials to create your account
      </p>
      <form onSubmit={handleSignUpWithEmail}>
        <div className="mb-4">
          <label className="block text-gray-700 text-left font-[500] text-[14px]">
            Name
          </label>
          <input
            type="text"
            name="name"
            placeholder="Enter your name"
            className="w-full px-4 py-2 border rounded-xl text-black border-opacity-10 "
            onChange={handleInputChangeForEmail}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-left font-[500] text-[14px]">
            Email address
          </label>
          <input
            type="email"
            name="email"
            placeholder="Enter your email"
            className="w-full px-4 py-2 border rounded-xl text-black border-opacity-10 "
            onChange={handleInputChangeForEmail}
          />
        </div>
        <label className="block text-gray-700 text-left font-[500] text-[14px]">
          Password
        </label>
        <div className="flex justify-end mb-4">
          <input
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder="Enter password"
            className="w-full px-4 py-2 border rounded-xl text-black border-opacity-10 "
            onChange={handleInputChangeForEmail}
          />

          {/* Eye icon  */}
          <div className="text-black h-[20px] w-[20px] text-lg absolute mr-2 mt-2 ">
            {showPassword ? (
              <button type="button" onClick={() => setShowPassword(false)}>
                <FaEyeSlash />
              </button>
            ) : (
              <button type="button" onClick={() => setShowPassword(true)}>
                <FaEye />
              </button>
            )}
          </div>
        </div>
        <label className="block text-gray-700 text-left font-[500] text-[14px]">
          Confirm Password
        </label>
        <div className="flex justify-end mb-4">
          <input
            type={showConfirmPassword ? "text" : "password"}
            name="confirmPassword"
            placeholder="Enter password"
            className="w-full px-4 py-2 border rounded-xl text-black border-opacity-10 "
          />

          {/* Eye icon  */}
          <div className="text-black h-[20px] w-[20px] text-lg absolute mr-2 mt-2 ">
            {showConfirmPassword ? (
              <button
                type="button"
                onClick={() => setShowConfirmPassword(false)}
              >
                <FaEyeSlash />
              </button>
            ) : (
              <button
                type="button"
                onClick={() => setShowConfirmPassword(true)}
              >
                <FaEye />
              </button>
            )}
          </div>
        </div>
        <div className="mb-6 flex items-center">
          <input type="checkbox" id="terms" name="terms" className="mr-2" />
          <label htmlFor="terms" className="text-gray-500">
            I agree to the{" "}
            <button type="button" className="text-blue-500">
              terms & policy
            </button>
          </label>
        </div>
        <button
          type="submit"
          className="w-full bg-[#3D74FF] text-white py-2 rounded-2xl font-[700]"
          disabled={loading}
        >
          {loading ? <Loader marginY="1" /> : <p>SignUp</p>}
        </button>
      </form>
    </div>
  );

  //Signup with phone component
  const signUpWithPhone = (
    <div>
      <form onSubmit={handleSignUpWithPhone}>
        <div className="mt-10 mb-4">
          <label className="block text-gray-700 text-left font-[500] text-[14px]">
            Name
          </label>
          <input
            name="name"
            type="text"
            placeholder="Enter your name"
            className="w-full px-4 py-2 border rounded-xl text-black border-opacity-10 "
            onChange={handleInputChangeForPhone}
          />
        </div>
        <div className="mt-10 mb-4">
          <label className="block text-gray-700 text-left font-[500] text-[14px]">
            Country Code
          </label>
          <select
            value={selectedCode}
            onChange={(e) => setSelectedCode(e.target.value)}
            className="w-full px-4 py-2 border rounded-xl text-black border-opacity-10"
          >
            {countryCodes.countries.map((country) => (
              <option key={country.name} value={country.code}>
                {country.name} ({country.code})
              </option>
            ))}
          </select>
        </div>
        <div className="mt-10 mb-4">
          <label className="block text-gray-700 text-left font-[500] text-[14px]">
            Phone Number
          </label>
          <input
            name="phoneNumber"
            type="number"
            placeholder="Enter your phone number"
            className="w-full px-4 py-2 border rounded-xl text-black border-opacity-10 "
            onChange={handleInputChangeForPhone}
          />
        </div>
        <div className="mb-6 flex items-center">
          <input type="checkbox" id="terms" name="terms" className="mr-2" />
          <label htmlFor="terms" className="text-gray-500">
            I agree to the{" "}
            <button type="button" className="text-blue-500">
              terms & policy
            </button>
          </label>
        </div>
        <button
          type="submit"
          className="w-full bg-[#3D74FF] text-white py-2 rounded-2xl font-[700]"
          disabled={loading}
        >
          {loading ? <Loader marginY="1" /> : <p> Send OTP</p>}
        </button>
      </form>
    </div>
  );
  //Component when otp is sent
  const enterCode = (
    <div className="text-black flex flex-col gap-10 my-4">
      <p className="w-[343px] text-left text-[20px] font-[600]">
        Enter 6 digit verification code sent to your phone number
      </p>
      {/* Enter Otp box  */}
      <OTPInput
        value={otp}
        onChange={setOtp}
        numInputs={6}
        renderSeparator={<span>{"  "}</span>}
        renderInput={(props) => <input {...props} />}
        containerStyle={{
          width: "343px",
          display: "flex",
          justifyContent: "space-between",
        }}
        inputStyle={{ width: "50px", height: "50px" }}
      />
      {/* Resend otp visible when otp is sent */}
      <p className="text-[16px] font-[600] text-left">
        Didn{"'"}t receive a code?{" "}
        <span className="text-[#FF8682] cursor-pointer">
          <button type="button" onClick={handleResendOtp}>
            Resend
          </button>
        </span>
      </p>
      {/* Submit button  */}
      <button
        type="button"
        onClick={handleOtpSubmit}
        className="w-full bg-[#3D74FF] text-white py-2 rounded-2xl font-[700] "
      >
        {loading ? <Loader marginY="1" /> : <p>Submit</p>}
      </button>
    </div>
  );

  //Signup success
  const signUpSuccessComponent = (
    <div className="felx flex-col justify-center my-8">
      <div className="relative flex items-center justify-center">
        {/* Circle */}
        <svg
          className="h-[106px] w-[106px]"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            cx="12"
            cy="12"
            r="10"
            stroke="#69C984"
            strokeWidth="0.5"
          ></circle>
        </svg>
        {/* Checkmark */}
        <p className="absolute text-[#69C984] text-2xl flex items-center justify-center">
          <FaCheck />
        </p>
      </div>

      <div className="flex flex-col gap-10 justify-center text-center">
        {/* Message  */}
        <p className="font-[700] text-[22px] text-black">Success!</p>
        <p className="text-[18px] text-[#B6B6B6] w-[300px] mx-auto">
          Congratulations! You have been successfully authenticated
        </p>
        {/* Button to continue  */}
        {/* Submit button  */}
        <button
          onClick={() => {
            navigate("/");
          }}
          type="button"
          className="w-[367px] mx-auto bg-[#69C984] text-white py-2 rounded-2xl font-[700] mt-6"
        >
          Continue
        </button>
      </div>
    </div>
  );

  return (
    <>
      {currentUser && isTokenValid(token) ? (
        <Navigate to="/" />
      ) : (
        <div className="bg-white p-4 min-h-screen flex justify-center">
          {signUpSuccessful ? (
            signUpSuccessComponent
          ) : (
            <div className=" flex justify-center">
              <div className="bg-white  rounded-lg p-8 w-full max-w-4xl flex">
                {/* Left Side: Form */}
                <div className="w-1/2">
                  {/* Tabs for Email and Phone Number  */}
                  <div className="flex mb-6 font-semibold w-[320px] h-[52px]">
                    <button
                      className={`w-1/2 py-2 text-center rounded-l-lg border ${
                        tab === "email"
                          ? "bg-blue-500 text-white"
                          : "bg-white text-gray-700"
                      }`}
                      onClick={() => navigate(`/auth/signup?tab=email`)}
                    >
                      Email
                    </button>
                    <button
                      className={`w-1/2 py-2 text-center rounded-r-lg border ${
                        tab === "phone" || tab === "otpsent"
                          ? "bg-blue-500 text-white"
                          : "bg-white text-gray-700"
                      }`}
                      onClick={() => navigate("/auth/signup?tab=phone")}
                    >
                      Phone Number
                    </button>
                  </div>
                  <h1 className="text-[32px] mb-2 text-left text-black font-[500]">
                    Sign Up
                  </h1>

                  {/* Form for Email */}
                  {tab === "email" && signUpWithEmail}
                  {/* Form for Phone */}
                  {tab === "phone" && signUpWithPhone}
                  {/* Enter otp form  */}
                  {tab === "otpsent" && enterCode}

                  {/* Separator  */}
                  <div className="flex items-center justify-between mt-4">
                    <div className="border-t w-2/5 border-gray-300"></div>
                    <span className="text-black">Or</span>
                    <div className="border-t w-2/5 border-gray-300"></div>
                  </div>

                  {/* Signup with google button  */}
                  <GoogleAuth />

                  <p className="mt-6 text-center text-black font-semibold">
                    Have an account?{" "}
                    <button
                      type="button"
                      onClick={() => {
                        navigate("/auth/signin");
                      }}
                      className="text-blue-500"
                    >
                      Sign In
                    </button>
                  </p>
                </div>

                {/* Right Side: Image */}
                <div className="w-1/2 flex justify-center items-center pl-32 pb-20">
                  <img
                    src="https://res.cloudinary.com/djwfu7z21/image/upload/v1730902482/auth-2_aduztz.png"
                    alt="SignUp"
                    className="w-[30rem] h-[30rem] object-cover"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SignUp;
