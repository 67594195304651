import axios from "axios";

//import dynamic react version
const version = process.env.REACT_APP_VERSION;
// Base URL will be automatically picked based on the environment
const instance = axios.create({
  baseURL:
    process.env.REACT_APP_API_BASE_URL + `/${version}/api` ||
    `http://localhost:5000/${version}/api`, // Use environment variable or localhost for development
  // withCredentials: true, // If your backend needs cookies or authorization headers
});

export default instance;
