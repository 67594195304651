import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FaArrowLeft, FaEye } from "react-icons/fa";
import useApiRequest from "../../hooks/apiRequest.js";
import { useNavigate, useParams } from "react-router-dom";
import {
  ErrorToast,
  SuccessToast,
} from "../../components/common/toast/toast.js";
import PreviewBlog from "./previewBlog.jsx";
import BackButton from "../../components/common/BackButton.jsx";

const categories = [
  { value: 1, name: "Healthcare Technology" },
  { value: 2, name: "Inventory and HR Management" },
  { value: 3, name: "Telemedicine Innovations" },
  { value: 4, name: "Digital Transformation in Healthcare" },
  { value: 5, name: "Operational Efficiency" },
  { value: 6, name: "Patient Management" },
];
const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

const UpdateBlog = () => {
  const { loading, apiRequest } = useApiRequest();
  const navigate = useNavigate();
  const { blogId } = useParams(); // Get blog ID from params
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState(null);
  const [category, setCategory] = useState("");
  const [existingImageUrl, setExistingImageUrl] = useState(""); // To display existing image
  const [showPopUp, setShowPopUp] = useState(false);

  const [preview, setPreview] = useState(false);

  // Fetch the blog details when the component loads
  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await apiRequest(
          "GET",
          `/blog/get-blog-by-id/${blogId}`
        );
        const blog = response.data.blog;

        if (blog) {
          setTitle(blog.title);
          setAuthor(blog.author);
          setContent(blog.content);
          setCategory(blog.category);
          setExistingImageUrl(blog.image); // Set the existing image URL
        }
      } catch (error) {
        console.error("Error fetching blog details:", error);
      }
    };

    fetchBlogData();
  }, [blogId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Creating formData to send to the backend, including the image file if updated
    const formData = new FormData();
    formData.append("title", title);
    formData.append("author", author);
    formData.append("content", content);
    formData.append("category", category);

    // Append the image file if the user selected a new one
    if (image) {
      formData.append("image", image);
    }

    try {
      const res = await apiRequest(
        "PUT",
        `/blog/update-blog/${blogId}`,
        formData
      ); // Send PUT request
      SuccessToast(res.data?.message || "Blog updated successfully!");
      // Reset form or redirect
      navigate(`/blog/readblog/${blogId}`);
    } catch (error) {
      ErrorToast(error.response?.data?.message || "Error in updating blog.");
      console.error("Error updating blog post:", error);
    }
  };

  return (
    <div>
      <div className="mx-auto p-6 bg-white rounded-lg text-[#03045E]">
        <BackButton />

        <h1 className="text-3xl font-semibold mb-8 text-left ml-20">
          Update your blog
        </h1>

        <form
          onSubmit={handleSubmit}
          className="bg-[#f2f1fe] p-10 w-[1362px]  min-h-screen mx-auto rounded-lg"
        >
          {/* Image Upload */}
          <div className="mb-6">
            <label className="block mb-2 text-lg text-[#03045E]">
              Update Image
            </label>
            <div
              className={`relative border-dashed border-2 border-gray-400 rounded-lg p-4 flex flex-col gap-4 justify-center items-center text-[#03045E] hover:bg-gray-100 transition-all w-[939.21px] h-[361px] mx-auto bg-white`}
            >
              <input
                type="file"
                onChange={(e) => setImage(e.target.files[0])}
                accept="image/*"
                className="hidden"
                id="imageUpload"
              />

              {/* Change Image Button */}
              <label
                htmlFor="imageUpload"
                className="cursor-pointer py-2 px-4 border border-[#03045E] rounded-md bg-white p-2 hover:shadow-xl hover:scale-105 transition duration-200 absolute top-4 left-1/2 transform -translate-x-1/2"
              >
                <div className="flex gap-4 items-center">
                  <img
                    src="https://res.cloudinary.com/djwfu7z21/image/upload/v1730533223/createblog_bnc3hq.svg"
                    alt="Upload Icon"
                    className={`${image ? "hidden" : "block"}`} // Hide icon if image is uploaded
                  />
                  <span>{image ? "Change image" : "Upload an image"}</span>
                </div>
              </label>

              {/* Instruction Text */}
              {!image && (
                <p className="text-center mt-2 text-[#03045E]">
                  Drag and drop or click to upload images
                </p>
              )}

              {/* Display the uploaded image inside the main block */}
              {image && (
                <div className="mt-10 w-full flex justify-center">
                  <img
                    src={URL.createObjectURL(image)} // Generate preview URL for the image file
                    alt="Uploaded"
                    className="max-w-[80%] max-h-[300px] rounded-lg"
                  />
                </div>
              )}

              {/* Display the existing image if available */}
              {!image && existingImageUrl && (
                <div className="w-full flex justify-center">
                  <img
                    src={existingImageUrl}
                    alt="Existing"
                    className="mt-4 w-[10rem] h-[10rem] rounded-lg"
                  />
                </div>
              )}
            </div>
          </div>

          {/* Blog Title */}
          <p className="flex justify-start ml-52 text-lg mt-12">Blog Heading</p>
          <div className=" w-[1201px] h-[97px] mx-auto">
            <input
              type="text"
              placeholder="Enter Blog title....."
              className="w-[900px] p-4 border border-gray-300 rounded-lg"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>

          {/* Author Name */}
          <p className="flex justify-start ml-52 text-lg">Author Name</p>
          <div className=" w-[1201px] h-[97px] mx-auto text-[#03045E]">
            <input
              type="text"
              placeholder="Name of the author"
              className="w-[900px] p-4 border border-gray-300 rounded-lg"
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
            />
          </div>

          {/* Category Input */}
          <div className="mb-6 w-[1201px] h-[97px] mx-auto text-[#03045E]">
            <label className="block mb-2 text-lg">Select Blog Category</label>
            <select
              className="w-[900px] p-4 border border-gray-300 rounded-lg"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              {categories.map((item) => {
                return (
                  <option key={item.name} value={item.value}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>

          {/* Blog Content */}
          <div className="mb-6 w-[1204px] mx-auto ">
            <label className="block mb-2 text-lg">
              Update the blog content
            </label>
            <ReactQuill
              theme="snow"
              modules={modules}
              formats={formats}
              value={content}
              onChange={setContent}
              className="rounded-lg w-full bg-white"
              required
            />
          </div>

          {/* Buttons */}
          <div className="flex justify-center gap-4 items-center mt-8">
            <button
              type="button"
              className="border border-[#03045E] text-[#03045E] px-6 py-3 rounded-md bg-white transition-colors"
              disabled={loading}
              onClick={() => {
                setShowPopUp(true);
              }}
            >
              Update
            </button>
            <button
              type="button"
              className="flex items-center space-x-1 bg-gray-200 px-4 py-3 rounded-md hover:bg-gray-300 transition-colors"
            >
              <FaEye size={16} />
              <span>Watch Preview</span>
            </button>
            <button
              type="button"
              className="bg-red-600 text-white px-6 py-3 rounded-md hover:bg-red-500 transition-colors"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </button>
          </div>
          {/* Confirmation popup  */}
          {showPopUp && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white p-6 rounded-md shadow-xl max-w-sm w-full">
                <h3 className="text-lg font-semibold">Are you sure?</h3>
                <p className="mt-2">Do you want to proceed with this action?</p>
                <div className="mt-4 flex justify-end gap-4">
                  <button
                    className="bg-gray-300 px-4 py-2 rounded-md hover:bg-gray-400"
                    onClick={() => {
                      setShowPopUp(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-800"
                  >
                    {loading ? "Updating... " : "Confirm Update"}
                  </button>
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default UpdateBlog;
