import React from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaWhatsapp,
} from "react-icons/fa"; // Include WhatsApp icon from react-icons
import "./footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-section customer-services">
        {/* <h3>Customer Services</h3>
        <ul>
          <li>
            <a href="#pricing">Pricing</a>
          </li>
        </ul> */}
      </div>
      <div className="footer-section social-media">
        <h2 style={{ marginTop: "40px" }}>Connect With Us On Social Media</h2>
        <div className="social-icons">
          <a
            href="https://www.instagram.com/clinix_sphere/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram />
          </a>

          <a
            href="https://x.com/ClinxSphere"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter />
          </a>
          <a
            href="https://www.linkedin.com/company/clinixsphere/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedinIn />
          </a>
        </div>
      </div>
      {/* <div className="footer-section navigation">
        <h3>Navigation</h3>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/blog">Blog</Link>
          </li>
          <li>
            <Link to="/contact">Contact Us</Link>
          </li>
        </ul>
      </div> */}
      <div className="footer-bottom">
        <p>© 2024 Clinix Sphere. All Rights Reserved.</p>
        <a href="#terms">Terms and Conditions | Privacy Policy</a>
      </div>
    </footer>
  );
};

export default Footer;
