import React from "react";
import "./keyFeatures.css";

const KeyFeatures = () => {
  const features = [
    {
      title: "Independent Control",
      description:
        "Take control of your patient data and interactions. Clinix Sphere offers a private digital space for secure management of all healthcare needs.",
    },
    {
      title: "Quick, Custom Setup",
      description:
        "Your practice, your brand—in minutes. Clinix Sphere helps you launch a custom app tailored to your needs without the usual wait.",
    },
    {
      title: "Unified Medical Network",
      description:
        "Clinix Sphere connects doctors, patients, labs, and chemists, ensuring all stakeholders communicate seamlessly across the healthcare journey.",
    },
  ];

  return (
    <section className="key-features">
      <h2 className="key-features__title">Key Features</h2>
      <div className="key-features__list">
        {features.map((feature, index) => (
          <div key={index} className="key-features__item">
            <div className="key-features__title-container">
              <h3 className="key-features__item-title">{feature.title}</h3>
              <div className="key-features__arrow"></div>
            </div>
            <p className="key-features__item-description">
              {feature.description}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default KeyFeatures;
