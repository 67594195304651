import React from "react";
import toast from "react-hot-toast";

// Success Toast
export const SuccessToast = (message) => {
  return toast.custom((t) => (
    <div
      className={`${
        t.visible ? "animate-enter" : "animate-leave"
      } max-w-sm w-full bg-white border border-green-400 shadow-md rounded-md pointer-events-auto flex items-center p-4 transition-transform transform ${
        t.visible ? "scale-100" : "scale-90"
      }`}
    >
      <div className="flex flex-row gap-6 items-center">
        <img
          src="https://cdn2.iconfinder.com/data/icons/web-and-apps-interface/32/OK-512.png"
          className="w-[2rem] h-[2rem] object-cover overflow-hidden"
          alt=""
        />
        <div className="flex-1">
          <p className="text-sm font-medium text-green-600">{message}</p>
        </div>
      </div>

      <div className="flex border-l ml-2 border-gray-200">
        <button
          onClick={() => toast.dismiss(t.id)}
          className="text-green-600 hover:text-green-500 focus:outline-none pl-4"
        >
          Close
        </button>
      </div>
    </div>
  ));
};

// Error Toast
export const ErrorToast = (message) => {
  return toast.custom((t) => (
    <div
      className={`${
        t.visible ? "animate-enter" : "animate-leave"
      } max-w-sm w-full bg-white border border-red-400 shadow-md rounded-md pointer-events-auto flex items-center p-4 transition-transform transform ${
        t.visible ? "scale-100" : "scale-90"
      }`}
    >
      <div className="flex flex-row gap-6 items-center">
        <img
          src="https://cdn.pixabay.com/photo/2015/06/09/16/12/error-803716_1280.png"
          className="w-[2rem] h-[2rem] object-cover overflow-hidden"
          alt=""
        />
        <div className="flex-1">
          <p className="text-sm font-medium text-red-600">{message}</p>
        </div>
      </div>
      <div className="flex border-l ml-2 border-gray-200">
        <button
          onClick={() => toast.dismiss(t.id)}
          className="text-red-600 hover:text-red-500 focus:outline-none pl-4"
        >
          Close
        </button>
      </div>
    </div>
  ));
};
