import React from "react";
import "./howItWorks.css";

const HowItWorks = () => {
  const steps = [
    {
      title: "After the Consultation",
      description:
        "Clinix Sphere sends the patient their prescription and any necessary instructions.",
      image: "After_the_Consultation.svg",
    },
    {
      title: "Partner Notifications",
      description:
        "Partner chemists or labs receive relevant updates for follow-up needs, like lab tests or prescriptions.",
      image: "Partner_Notifications.svg",
    },
    {
      title: "Results & Records Return",
      description:
        "Lab results or records are shared directly with the doctor, simplifying the patient’s continuity of care.",
      image: "Results_&_Records_Return.svg",
    },
  ];

  return (
    <section className="how-it-works">
      <h2 className="how-it-works__title">How It Works</h2>
      <div className="how-it-works__timeline">
        <div className="how-it-works__shape how-it-works__shape--1"></div>
        <div className="how-it-works__shape how-it-works__shape--2"></div>
        <div className="how-it-works__line"></div>
        {steps.map((step, index) => (
          <div
            key={index}
            className={`how-it-works__step ${
              index % 2 !== 0 ? "how-it-works__step--right" : ""
            }`}
          >
            <div className="how-it-works__image-container">
              <img
                src={step.image}
                alt={step.title}
                className="how-it-works__image"
              />
            </div>
            <div className="how-it-works__content">
              <h3 className="how-it-works__step-title">{step.title}</h3>
              <p className="how-it-works__description">{step.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default HowItWorks;
