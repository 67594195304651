import React from "react";
import "./additionalCta.css";
import { useNavigate } from "react-router-dom";

const AdditionalCta = () => {
  const navigate = useNavigate();
  return (
    <>
      <hr
        style={{
          border: "none",
          borderTop: "2px solid #dddddd",
          margin: "1px 0",
        }}
      />
      <div className="additional-cta">
        <h1 className="cta-text">
          Bring Secure, Patient-Centered Care to <br />
          Your Practice Today
        </h1>
        <button
          className="addcta-button"
          onClick={() => {
            navigate("/contact");
          }}
        >
          Start Now with Clinix Sphere
        </button>
      </div>
    </>
  );
};

export default AdditionalCta;
